.navbar-small-container{
    display: flex;
    flex-flow: row;
    margin: 0 0 0 0 ;
    padding: 0 1.5rem 0 1.5rem;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: nowrap;
    border-bottom: solid 0.5px rgba(163, 166, 170, 0.445);
    box-shadow: 1px 1px 5px rgba(151, 144, 151, 0.6);
    z-index:2;
    height: 100px;
    /* width: 100%; */
    background-color: #264653;
    /* overflow: hidden; */
    /* background-size: cover; */
}
.navbar-small-sub{
    
    height: 100px;
    width: 100%;
    /* overflow: hidden; */
    /* background-size: cover; */
}

.logo-side-drawer {
    height: 7rem;
    margin-top: 2rem;
    justify-content: flex-start;
    display: inline-flex;
}

.btn-drawer {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2rem;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1;
    width: 4rem;
    height:1.5rem;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    border: none;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
}

.btn-drawer:hover,
.btn-drawer:focus {
    color: #fd0505;
    outline: none;

}

#btn-drawer {
    text-decoration: none;
    align-content: center;
}

.side-drawer{
    display: flex;
    justify-content: flex-end;
    fill: #02B2BC;
    margin-right: 0.5rem;
    position: relative;
    font-size: 1.5rem;
    height: 5rem;
    cursor: pointer;
}