.footerRoot {
    background-color: #264653;
    color: #fff;
    bottom: 0;
    width: 100%;
    padding-top: 1rem;
    margin-top: 30rem;
    position:statis;
    overflow:hidden;
}

ul{
    padding-inline-start:0 ;
   padding: none;

}

.footerSmall{
    margin-top: auto;
}
.listItem{
    list-style-type: none;
    margin-top: 2rem;
    justify-content: flex-end;

}

.listLink:hover{
    color:#769ED2;
    text-decoration: none;
    list-style-type: none;
}
.logo-footer{
    margin-left: -4rem;
}
ul.li:hover{
    color:#1D58A8;

}

.footer-info{
    display: flex;
    margin-top: 1rem;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.contactus {
    font-size: small;
}
.list {
    text-decoration: none;
    list-style-type: none;
}

.listLink {
    text-decoration: none;
    list-style-type: none;
    color:#fff
}
.nav-menu-footer {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 15px;
    list-style: none;
    text-align: flex-start;
    width: 50vw;
    justify-content: start;
    margin-left: 1rem;
}


.copyright {
    text-align: center;
}
.non-profit {
    text-align: center;
    color:#fff;
}
.container{
    position: static;
}
