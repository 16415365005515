.NavbarItems {
    height: 100px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.1rem;
    color:#fff;
    border-bottom: solid 0.5px rgba(163, 166, 170, 0.445);
    box-shadow: 1px 1px 5px rgba(151, 144, 151, 0.6);
    background-color: #264653;
    overflow:hidden;
}

.logoset{
    display:flex;
    align-items: center;
}

.logo {
    height:15rem;
    width: 80%;
    justify-content: flex-end;
    margin-top: 7rem;
    margin-left: -3rem;
}

.logoName {
    height: 9rem;
    width: auto;
    margin-left: -8em;
    margin-top: -1rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 1px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: start;
    margin-left: 1rem;
    z-index: 1;
    font-size: 1rem;
}

.nav-item {
    text-decoration: none;
}


.navbar-nav {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    list-style-type: none
}


.nav-links {
    color: #02B2BC;
    font-weight: bold;
    font-size: large;
    text-decoration: none;
    padding: 2.2rem 1rem;
}

.nav-links:hover {
    transition: margin-right 0.3s;
    transform-origin: center bottom;
    transform: rotateX(-90deg) translateY(100%);
    color: #606161;
    border-bottom: #606161 solid 5px;
    /* border-top: #34455E solid 5px; */
}

.fa-bars {
    color: #1D58A8;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 1024px) {
   
    .NavbarItems {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        width:100px;
        background-color: #fd0505;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 35rem;
        position: absolute;
        top: 100px;
        left: -100%;
        opacity: 1;
        transition: all 2s ease;
    }

    .nav-menu.active {
        background: #1D58A8;
        left: 0;
        opacity: 1;
        transition: all 0.75s ease;
        z-index: 1;
        color: red;
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .nav-links {
        text-align: start;
        padding: 1rem;
        margin: 0rem auto;
        width: 100%;
        color: #fff;
        text-decoration: none;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        
    }

    .navbar-logo {
        position: absolute;
        top: 10px;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #1D58A8;
        font-size: 2rem;
    }
    
    .bottom-line {
        flex-direction: column;
    }

    .nav-menu.passive {
        display: none;
    }
    
}